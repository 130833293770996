import { flow } from '@/utils/mobileOnboarding';
import { merge as _merge } from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
  data: () => ({ flow: [] }),
  computed: {
    ...mapGetters(['hasActiveShopify']),
    industry() {
      const { industry } = this.$route.query;
      return industry || localStorage.getItem('om-onboarding-industry');
    },
    stage() {
      const { stage } = this.$route.query;
      return stage || localStorage.getItem('om-onboarding-income');
    },
  },
  watch: {
    $route() {
      this.flow = flow(this.whichContactFlow());
    },
  },
  methods: {
    whichContactFlow() {
      if (this.$i18n.locale === 'hu') {
        return 'normal';
      }
      if (this.hasActiveShopify && this.industry && this.industry === 'agency') {
        return 'shopifyAgency';
      }
      if (this.hasActiveShopify) {
        return 'shopify';
      }
      if (this.industry && this.industry === 'agency') {
        return 'agency';
      }
      if (this.stage && this.isMidLevel(this.stage)) {
        return 'midLevel';
      }
      return 'normal';
    },
    isMidLevel(stage) {
      return ['scaling', 'established'].includes(stage);
    },
    next() {
      if (this.validate()) {
        const extras = this.mergeExtrasWithCurrent();

        if (
          this.hasActiveShopify &&
          extras.query &&
          extras.query.industry &&
          extras.query.industry === 'agency'
        ) {
          this.flow = flow('shopifyAgency');
        }

        if (
          this.$i18n.locale !== 'hu' &&
          extras.query &&
          extras.query.stage &&
          this.isMidLevel(extras.query.stage)
        ) {
          this.flow = flow('midLevel');
        }

        this.$router.push({ name: this.getNextRouteName(), ...extras });
      }
    },
    getNextRouteName() {
      const currentIndex = this.flow.findIndex((f) => f === this.$route.name);
      return this.flow[currentIndex + 1];
    },
    mergeExtrasWithCurrent() {
      const extras = this.getExtras();
      return {
        params: _merge(this.$route.params, extras.params || {}),
        query: _merge(this.$route.query, extras.query || {}),
      };
    },
    getExtras() {
      return {};
    },
    validate() {
      return false;
    },
  },
  mounted() {
    this.flow = flow(this.whichContactFlow());
  },
};
