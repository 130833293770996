<template lang="pug">
.d-flex.flex-column.align-items-center.justify-content-center.p-3
  img.mb-4(src="@/assets/admin/svg/waving.svg" style="width: 18vh; height: auto")
  h3.brand-mobile-onboarding-title {{ $t('mobileOnboarding.titles.welcome') }}
  .brand-mobile-onboarding-subtitle {{ $t('mobileOnboarding.subtitles.welcome') }}
  om-button.brand-mobile-onboarding-btn(primary large @click="next") {{ $t('continue') }}
</template>

<script>
  import { steps } from '@/utils/mobileOnboarding';
  import onboardingHelpers from '@/mixins/mobileOnboarding';
  import saveStep from '@/mixins/saveOnboardingProgress';

  export default {
    name: 'MobileOnboardingWelcome',
    mixins: [onboardingHelpers, saveStep],
    data: () => ({ steps }),
    methods: {
      validate() {
        return true;
      },
    },
  };
</script>
